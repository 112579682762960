import React, { useEffect, useState } from 'react'
import {navigate} from 'gatsby'
import {SEO, Input, Datepicker, Select, Button, Modal} from 'components'
import {CandidateService, CountryService, PlanService} from 'services'
import {useSelector, useDispatch} from 'react-redux'
import {RootState} from 'reducers'
import {Layout, StripeCard} from 'widgets'
import {TravelUser ,CheckoutBg, AirplaneSeparator, PreExistence, DamageToThirdParties, Pets, TechProtection, PreExistenceSelected, DamageToThirdPartiesSelectedd, PetsSelected, TechProtectionSelected} from 'images'
import {showError, ASSISNET_CARD_LINK, setLoading, quitLoading, formatMiles, showSuccess} from 'utils'
import moment from 'moment'
import { PayPalButton } from "react-paypal-button-v2";
// import { Plan } from 'models'
type Plan = {
    insurance: string,
    contract: string,
    destiny: string,
    travel_days: string,
    back_date: string,
    start_date: string,
    id_sql: string,
    logo: string,
    coin: string,
    name: string,
    plan_name: string,
    passengers: string,
    price: string,
}
type ContactForm = {
    name: string,
    email: string,
    phone: string
}

type Aditional = {
    label: string,
    image: any,
    selected: any,
    isSelected: boolean
}
type Form = {
    name: string,
    lastname: string,
    birthdate: Date | undefined,
    document_type: string,
    dni: string,
    terms: boolean,
    aditionals: Aditional[]
    key: number //Ignorar por binding de mapeo para botones y seteo del mismo por cambio por datos de usuario
}
type ValidationForm = {
    name?: boolean,
    lastname?: boolean,
    birthdate?: boolean,
    document_type?: boolean,
    dni?: boolean,
}
const Checkout = () => {
    const dispatch = useDispatch()
    const IFormState: Form = {
        name: '',
        lastname: '',
        document_type: '',
        birthdate: undefined,
        dni: '',
        terms: false,
        aditionals: [],
        key: 0
    }
    const IContactForm: ContactForm = {
        name: '',
        email: '',
        phone: ''
    }
    const aditionalCost = 20
    const IAditionalsState: Aditional[] = [
        {
            label: 'Pre-existencia',
            image: PreExistence,
            selected: PreExistenceSelected,
            isSelected: false
        },
        {
            label: 'Daños a terceros',
            image: DamageToThirdParties,
            selected: DamageToThirdPartiesSelectedd,
            isSelected: false
        },
        {
            label: 'Mascotas',
            image: Pets,
            selected: PetsSelected,
            isSelected: false
        },
        {
            label: 'Tech protection',
            image: TechProtection,
            selected: TechProtectionSelected,
            isSelected: false
        }
    ]
    const documents = [
        {
            label: 'Cédula',
            value: 1
        },
        {
            label: 'Pasaporte',
            value: 2
        },
        {
            label: 'DNI',
            value: 3
        }
    ]
    const quoteRequest = useSelector((state: RootState) => state.quoteRequest)
    const selectedPlan = useSelector((state: RootState) => state.plan)
    const [aditionals, setAditionals] = React.useState(IAditionalsState)
    const [plan, setPlan] = React.useState<Plan>()
    const [form, setForm] = React.useState<Form[]>([IFormState])
    const [total, setTotal] = React.useState<number>(0)
    const [activeForm, setActiveForm] = React.useState<Form>(IFormState)
    const [validationForm, setValidationForm] = React.useState<ValidationForm>({})
    const [emergencyContactForm, setEmergencyContactForm] = React.useState<ContactForm>(IContactForm)
    const [showContactForm, setShowContactForm] = React.useState<boolean>(false)
    const [visible, setVisible] = React.useState<boolean>(false)
    const [country, setCountry] = React.useState<string>('')
    const [countries, setCountries] = React.useState<Array<{label: string, value: number | string}>>([])
    const load = async () => {
        setLoading()
        try {
            const country = await CountryService.get()
            // const countries = await CountryService.countries()
            // const origins = countries.map(element => {
            //     return {
            //         label: element.name,
            //         value: element.alpha2Code
            //     }
            // })
            setCountries([
                {
                    label: 'Venezuela',
                    value: 'Venezuela'
                }
            ])
            setCountry(country.country)
            if(selectedPlan && selectedPlan.id_sql){
                const {datos: data} = await PlanService.check({
                    id_plan: selectedPlan.id,
                    id_sql: selectedPlan.id_sql
                })
                const candidate = await CandidateService.get(data.id_sql);
                console.log('candidate', candidate);
                setPlan({
                    insurance: data.aseguradora,
                    contract: data.contrato,
                    destiny: data.destino,
                    travel_days: data.dias_viaje,
                    back_date: data.fecha_regreso,
                    start_date: data.fecha_salida,
                    id_sql: data.id_sql,
                    logo: data.logo,
                    coin: data.moneda,
                    name: data.nombre,
                    plan_name: data.nombrePlan,
                    passengers: candidate.num_pasajeros.toString(),
                    price: data.precio
                })
                let _form: Form[] = [];
                for (let index = 0; index < parseInt(candidate.num_pasajeros.toString()); index++) {
                    const newForm: Form = {
                        ... IFormState,
                        name: index === 0 ? (quoteRequest?.name || '') :  '',
                        lastname: index === 0 ? (quoteRequest?.lastname || '') :  '',
                        birthdate: moment().subtract(quoteRequest?.passengers?.[index], 'years').toDate(),
                        document_type: documents[0].value.toString()
                    }
                    _form.push(newForm)
                }
                setTotal(parseFloat(data.precio) * (quoteRequest?.passengers?.length || 1))
                setForm(_form)
                setActiveForm(_form[0])
            }
        } catch (error) {
            console.log('>>: error > ', error)
            showError(error.message)
            navigate('/buscar')
        } finally{
            quitLoading()
        }
    }
    React.useEffect(() => {
        load()
    }, [])
    const change = (key: keyof Form, value: string | Date | Boolean) => {
        const _form: Form = {
            ... activeForm,
            [key]: value
        }
        let _form_: Form[] = [... form]
        _form_[activeForm.key] = _form
        setActiveForm(_form);
        setForm(_form_)
    }
    const changeContactForm = (key: keyof ContactForm, value: string | Date) => {
        const contact: ContactForm = {
            ... emergencyContactForm,
            [key]: value
        }
        setEmergencyContactForm(contact)
    }
    const selectAditional = (i: number) => {
        let _aditionals = [... aditionals]
        const isSelected = !_aditionals[i].isSelected
        _aditionals[i].isSelected = isSelected
        const _total = !isSelected ? total - aditionalCost : total + aditionalCost
        setAditionals(_aditionals)
        setTotal(_total)
    }
    const submit = async (token: string) => {
        setLoading()
        setVisible(false)
        try {
            const pasajeros = form.map((element: Form, i: number) => {
                return{
                    nombres: element.name,
                    apellidos: element.lastname,
                    fecha_nacimiento: moment(element.birthdate).format('YYYY-MM-DD'),
                    tipo_documento: element.document_type,
                    descripcion_documento: element.dni,
                    adicionales: ''
                }
            })
            // const _country = countries.find(element => element.value === country)?.label || country
            const params = {
                id_sql: plan?.id_sql,
                stripeToken: token,
                monto: total,
                // country: _country,
                moneda: 'usd',
                pasajeros,
                contactoEmergencia: emergencyContactForm
            }
            await PlanService.checkout(params)
            showSuccess('Su pago se ha realizado exitosamente')
            navigate('/checkout-finish')
            dispatch({
                type: 'REMOVE_PLAN'
            })
        } catch (error) {
            console.log('>>: error > ', error)
            showError(error?.response?.data?.msg || '')
        }finally{
            quitLoading()
        }
    }
    const openStripe = async () => {
        setLoading()
            setVisible(true)
        // quitLoading()
    }

    const onBlurField = (key: keyof ValidationForm) => {
        const value = activeForm[key]
        let _validationForm = {... validationForm}
        if(!value && !validationForm[key]){
            _validationForm[key] = true
        }else{
            delete _validationForm[key]
        }
        setValidationForm(_validationForm)
    }

    const onFocusField = (key: keyof ValidationForm) => {
        let _validationForm = {... validationForm}
        delete _validationForm[key]
        setValidationForm(_validationForm)
    }

    const changeTabByEmptyFormItem = (keys: string[], i: number) => {
        const _activeForm = {
            ... form[i],
            key: i
        }
        let _validationForm = {}
        keys.forEach(element => {
            //@ts-ignore
            _validationForm[element] = true
        })
        setActiveForm(_activeForm)
        setValidationForm(_validationForm)
    }

    const checkForm = (): boolean => {
        const hasError = form.some((item: Form, i: number) => {
            const element = {...item}
            //@ts-ignore
            delete element.terms
            //@ts-ignore
            delete element.key
            const keys = Object.keys(element)
            let emptyKeys: string[] = []
            keys.forEach((_element, _i) => {
                //@ts-ignore
                if(!element[_element]){
                    emptyKeys.push(_element)
                }
            })
            const exist = emptyKeys.length > 0
            if(exist){
                changeTabByEmptyFormItem(emptyKeys, i)
            }
            return exist
        })
        return hasError
    }
    
    const changeTab = (element: Form, i: number) => {
        setValidationForm({})
        setActiveForm({
            ... element,
            key: i
        })
    }

    const checkToPay = () => {
        let hasError = checkForm()
        if(!hasError)
            openStripe()
    }
    const handleCountry = (_country: string) => {
        setCountry(_country)
    }

    const paypalProcess = (details: any, data: any) => {

        

        console.log(details);
        console.log(data);
        // alert("Transaction completed by " + details.payer.name.given_name);

        // OPTIONAL: Call your server to save the transaction
        const pasajeros = form.map((element: Form, i: number) => {
            return{
                nombres: element.name,
                apellidos: element.lastname,
                fecha_nacimiento: moment(element.birthdate).format('YYYY-MM-DD'),
                tipo_documento: element.document_type,
                descripcion_documento: element.dni,
                adicionales: ''
            }
        })
        // const _country = countries.find(element => element.value === country)?.label || country
        const params = {
            id_sql: plan?.id_sql,
            monto: total,
            moneda: 'usd',
            orderId: data.orderID,
            transactionId: details.purchase_units[0].payments.captures[0].id,
            status: details.status,
            // country: _country,
            pasajeros
        }
        PlanService.checkoutPaypal(params)
        showSuccess('Su pago se ha realizado exitosamente')
            navigate('/checkout-finish')
            dispatch({
                type: 'REMOVE_PLAN'
            })
        return 
    }

    const [state, setstate] = useState(false);
    const [statePayment, setstatepayment] = useState(false);

    

    const terminos = async() => { 
        
        await setstate(!state);
        
    }

    useEffect(() => {
        if ( 
            activeForm.birthdate != undefined && 
            activeForm.dni != '' && 
            activeForm.document_type != '' && 
            activeForm.lastname != '' && 
            activeForm.name != '' && state == true){
                    console.log(state);
                 setstatepayment(true);
        }
        else{
            console.log(state);
             setstatepayment(false);
        }
        return () => {
        }
    }, [state,activeForm])
    
    return(
        <React.Fragment>
            <SEO
                title="Tu consulta en Compara Assist."
                description="Somos la forma segura y sencilla para consultar las distintas opciones de seguros de viajes y de salud."
                keywords="seguros de viaje mercantil, seguro de viaje internacional mapfre, seguros de viaje para europa, seguro de viaje para españa, seguro de viaje internacional barato, seguros de viaje internacionales, asegura tu viaje, seguro médico internacional barato, seguro médico internacional sanitas, seguro de salud, póliza de seguro internacional, seguro de viaje internacional, seguro de vida internacional, seguro de viajero internacional"
            />
            <Modal
                visible={visible}
                onClose={() => setVisible(false)}
                styles={{
                    overlay:{
                        zIndex: 2
                    },
                    content:{
                        overflowX: 'hidden',
                        maxWidth: '30%',
                        maxHeight: '50%',
                        top: '20%',
                        left: '35%'
                    }
                }}
                id="checkout-stripe-modal"
            >
                <StripeCard
                    onSubmit={(token: string) => submit(token)}
                    email={quoteRequest?.email || ''}
                    total={total}
                />
            </Modal>
            <Layout>
                <div className="checkout container">
                    <div className="row">
                        <div
                            className="col-md-6 col-sm-12 order-last order-md-first"
                        >
                            <div className="top-figure">
                                <p className="text-white">
                                    Ya estás a un paso de concluir, tu orden {quoteRequest?.name}.
                                </p>
                            </div>
                            <div className="airplane-separator">
                                <img
                                    src={AirplaneSeparator}
                                    alt=""
                                />
                            </div>
                            {/* Select de pais de origen */}
                            {/* <div className="container">
                                <Select
                                    label="Pais de Origen"
                                    onChange={(value: string) => handleCountry(value)}
                                    name="country"
                                    options={countries}
                                    value={country}
                                />
                            </div> */}
                            <div className="container">
                                <div className="w-100 text-left p-2">
                                    <p className="text-dark-gray m-0 mb-1 font-weight-bold">
                                        Viajeros
                                    </p>
                                    <div className="container">
                                        <div className="row">
                                            {
                                                form.map((element: Form, i: number) => {
                                                    return(
                                                        <div
                                                            className={"col-3 my-1 mx-1 col-md-2 col-sm-4 p-0 d-flex justify-content-center align-items-center cursor-pointer rounded-pill"+(activeForm?.key === i ? ' bg-purple' : ' bg-dark-gray')}
                                                            key={i}
                                                            onClick={() => changeTab(element, i)}
                                                        >
                                                            <img
                                                                className="m-0 p-1 passenger-icon"
                                                                src={TravelUser}
                                                                alt={'Pasajero '+(i+1)}
                                                            />
                                                            <span className="text-white m-0">
                                                                {i+1}
                                                            </span>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="form">
                                    <div className="container">
                                        <Input
                                            name="name"
                                            onChange={(value: string) => change('name', value)}
                                            value={activeForm.name}
                                            required
                                            hasError={validationForm.name}
                                            onBlur={() => onBlurField('name')}
                                            onFocus={() => onFocusField('name')}
                                            label="Nombres"
                                            labelClass="font-weight-bold"
                                        />
                                        <Input
                                            name="lastname"
                                            onChange={(value: string) => change('lastname', value)}
                                            value={activeForm.lastname}
                                            label="Apellidos"
                                            required
                                            hasError={validationForm.lastname}
                                            onBlur={() => onBlurField('lastname')}
                                            onFocus={() => onFocusField('lastname')}
                                            labelClass="font-weight-bold"
                                        />
                                        <Datepicker
                                            label="Fecha de nacimiento"
                                            labelColor="dark-gray"
                                            labelClass="font-weight-bold"
                                            showMonthDropdown
                                            showYearDropdown
                                            maxDate={moment().toDate()}
                                            value={moment(activeForm.birthdate).toDate()}
                                            onChange={(value: Date) => change('birthdate', value)}
                                            required
                                            hasError={validationForm.birthdate}
                                            onBlur={() => onBlurField('birthdate')}
                                            onFocus={() => onFocusField('birthdate')}
                                        />
                                        <div className="row">
                                            <div className="col-md-4 col-sm-12">
                                                <Select
                                                    value={activeForm.document_type}
                                                    name="document_type"
                                                    label="Tipo de documento"
                                                    labelClass="w-100 ellipsis font-weight-bold"
                                                    options={documents}
                                                    onChange={(value: string) => change('document_type', value)}
                                                    required
                                                    hasError={validationForm.birthdate}
                                                    onBlur={() => onBlurField('birthdate')}
                                                    onFocus={() => onFocusField('birthdate')}
                                                />
                                            </div>
                                            <div className="col-md-8 col-sm-12">
                                                <Input
                                                    name="dni"
                                                    value={activeForm.dni}
                                                    onChange={(value: string) => change('dni', value)}
                                                    label="#"
                                                    required
                                                    hasError={validationForm.dni}
                                                    onBlur={() => onBlurField('dni')}
                                                    onFocus={() => onFocusField('dni')}
                                                    labelClass="font-weight-bold"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card aditionals-card">
                                <div className="card-header bg-purple bordered">
                                    <p className="text-white text-center w-100 m-0">
                                        Agregar contactos de emergencia
                                    </p>
                                </div>
                                <div className="card-body">
                                    <div className="container w-100 text-center">
                                        {/* <div className="row">
                                            {
                                                aditionals.map((element, i: number) => {
                                                    return(
                                                        <div
                                                            className="item col"
                                                            key={i}
                                                        >
                                                            <img
                                                                src={element.isSelected ? element.selected : element.image}
                                                                alt={element.label}
                                                                style={{
                                                                    maxWidth: '60%'
                                                                }}
                                                                onClick={() => selectAditional(i)}
                                                                className="m-0 cursor-pointer"
                                                            />
                                                            <p className="text-dark-gray">
                                                                {element.label}
                                                            </p>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div> */}
                                        <div className="emergency-contact-container d-flex justify-content-center align-items-center">
                                            <div className="row">
                                                <div className="col-md-12 col-sm-12">
                                                    <div className="row">
                                                        <div className="col">
                                                            <p className="ellipsis text-dark-gray m-0 p-1 d-flex justify-content-center align-items-center">
                                                                Contacto en caso de emergencia
                                                            </p>
                                                        </div>
                                                        <div className="col">
                                                            <Button
                                                                className="success text-white rounded-pill"
                                                                label="Agregar contacto"
                                                                onClick={() => setShowContactForm(!showContactForm)}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-12 col-sm-12">
                                                    <div className="container w-100">
                                                        <div className="contact-form">
                                                            {
                                                                showContactForm && (
                                                                    <div className="container">
                                                                        <Input
                                                                            name="name"
                                                                            label="Nombres y apellidos"
                                                                            value={emergencyContactForm.name|| ''}
                                                                            onChange={(value: string) => changeContactForm('name', value)}
                                                                            labelClass="font-weight-bold"
                                                                        />
                                                                        <div className="row">
                                                                            <div className="col-md-6 col-sm-12">
                                                                                <Input
                                                                                    name="email"
                                                                                    type="email"
                                                                                    value={emergencyContactForm.email || ''}
                                                                                    label="Email"
                                                                                    onChange={(value: string) => changeContactForm('email', value)}
                                                                                    labelClass="font-weight-bold"
                                                                                />
                                                                            </div>
                                                                            <div className="col-md-6 col-sm-12">
                                                                                <Input
                                                                                    name="phone"
                                                                                    value={emergencyContactForm.phone || ''}
                                                                                    label="Teléfono"
                                                                                    onChange={(value: string) => changeContactForm('phone', value)}
                                                                                    labelClass="font-weight-bold"
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="container">
                                <div className="form-check">
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="exampleCheck1"
                                        //checked={form.terms}
                                        //onClick={() => change('terms', !form.terms)}
                                        onClick={ terminos }
                                    />
                                    <label className="form-check-label" htmlFor="exampleCheck1">
                                        Declaro que he leído y acepto los alcances y límites del producto especificado en el &nbsp;
                                        <a
                                            className="text-purple cursor-pointer"
                                            href={'https://dev.comparaassist.com/files/contrats/'+plan?.contract}
                                            target="_blank"
                                        >
                                            Contrato de {plan?.insurance} &nbsp;
                                        </a>
                                         y los
                                        <span className="text-purple cursor-pointer">
                                            &nbsp; términos y condiciones &nbsp;
                                        </span>
                                        de Compara Assist.
                                    </label>
                                </div>
                                <div className="container">
                                    <div className="w-100 text-center p-2">
                                        {
                                            statePayment ? <PayPalButton
                                                    amount={total}
                                                    // shippingPreference="NO_SHIPPING" // default is "GET_FROM_FILE"
                                                    onSuccess={ paypalProcess }
                                                    options={{
                                                        clientId: process.env.GATSBY_PAYPAL_KEY
                                                    }}
                                                />
                                            : ''
                                        }
                                    
                                        {/* <Button
                                            label="Comprar ahora"
                                            className="success text-white"
                                            onClick={() => checkToPay()}
                                        /> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            className="col-md-6 col-sm-12"
                            id="right-bar"
                            style={{
                                backgroundImage: `url(${CheckoutBg})`,
                                backgroundSize: 'cover'
                            }}
                        >
                            <div className="float-widget">
                                <div className="card">
                                    <div className="card-header bg-green">
                                        <div className="row">
                                            <div className="col-md col-sm-12">
                                                <p className="text-left text-white m-0">
                                                    Total
                                                </p>
                                            </div>
                                            <div className="col-md col-sm-12">
                                                <h3 className="text-right text-white m-0">
                                                    {formatMiles(total)} {plan?.coin}
                                                </h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <div className="logo">
                                            <img
                                                src={process.env.GATSBY_API_ASSETS+'img/mk/insurances/'+plan?.logo}
                                                alt={plan?.plan_name}
                                            />
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6 col-sm-12">
                                                <p className="text-purple m-1">
                                                    Fecha
                                                </p>
                                            </div>
                                            <div className="col-md-6 col-sm-12">
                                                <p className="text-purple font-weight-bold text-uppercase m-1">
                                                    {plan?.plan_name}
                                                </p>
                                            </div>
                                            <div className="col-md-6 col-sm-12">
                                                <p className="text-purple m-1">
                                                    Destino
                                                </p>
                                            </div>
                                            <div className="col-md-6 col-sm-12">
                                                <p className="text-purple font-weight-bold m-1">
                                                    {plan?.destiny}
                                                </p>
                                            </div>
                                            <div className="col-md-6 col-sm-12">
                                                <p className="text-purple m-1">
                                                    Fecha de salida
                                                </p>
                                            </div>
                                            <div className="col-md-6 col-sm-12">
                                                <p className="text-purple font-weight-bold m-1">
                                                    {plan?.start_date}
                                                </p>
                                            </div>
                                            <div className="col-md-6 col-sm-12">
                                                <p className="text-purple m-1">
                                                    Fecha de regreso
                                                </p>
                                            </div>
                                            <div className="col-md-6 col-sm-12">
                                                <p className="text-purple font-weight-bold m-1">
                                                    {plan?.back_date}
                                                </p>
                                            </div>
                                            <div className="col-md-6 col-sm-12">
                                                <p className="text-purple m-1">
                                                    Pasajeros
                                                </p>
                                            </div>
                                            <div className="col-md-6 col-sm-12">
                                                <p className="text-purple font-weight-bold m-1">
                                                    {plan?.passengers}
                                                </p>
                                            </div>
                                            <div className="col-md-6 col-sm-12">
                                                <p className="text-purple m-1">
                                                    Tiempo del viaje
                                                </p>
                                            </div>
                                            <div className="col-md-6 col-sm-12">
                                                <p className="text-purple font-weight-bold m-1">
                                                    {plan?.travel_days+' días'}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="card-footer">
                                            <p className="text-purple m-0">
                                                Para visualizar las opciones de pago, debes completar los datos solicitados
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        </React.Fragment>
    )
}

export default Checkout